import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import Motto from "@components/motto";
import ITSolutionBox from "@components/box-large-image/layout-02";
import { SectionWrap } from "./style";

const ServiciosArea = () => {
  const serviciosData = useStaticQuery(graphql`
    {
      toldos: file(relativePath: { eq: "servicios/toldos.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      sonido: file(relativePath: { eq: "servicios/sonido.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      luces: file(relativePath: { eq: "servicios/luces.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pantallas: file(relativePath: { eq: "servicios/pantallas.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      barras: file(relativePath: { eq: "servicios/barras.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      horaloca: file(relativePath: { eq: "servicios/hora-loca.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const { toldos, sonido, luces, pantallas, barras, horaloca } = serviciosData;

  const items = [
    { id: 1, title: "Toldos", image: toldos },
    { id: 2, title: "Sonido", image: sonido },
    { id: 3, title: "Luces", image: luces },
    { id: 4, title: "Pantallas", image: pantallas },
    { id: 5, title: "Barras", image: barras },
    { id: 6, title: "Hora Loca", image: horaloca },
  ];
  return (
    <SectionWrap>
      <Container>
        {items && (
          <Row>
            {items.map((item) => {
              return (
                <Col key={item.id} className="box-item" lg={4} md={6}>
                  <ITSolutionBox title={item.title} image={item.image} />
                </Col>
              );
            })}
          </Row>
        )}
        <Motto
          text="¡Llámenos hoy para hablar con un representante de"
          linkText="Eventos Ghupa para su fiesta!"
          path="/contacto"
        />
      </Container>
    </SectionWrap>
  );
};

export default ServiciosArea;
