import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import BoxIcon from "@components/box-icon/layout-04";
import { HeadingType, ItemType } from "@utils/types";
import {
  SolutionsWrapper,
  SolutionBox,
  SolutionBoxItem,
  StyledHeading,
} from "./style";

const SolutionesArea = () => {
  const items = [
    { id: 0, title: "Catering" },
    { id: 1, title: "Menaje" },
    { id: 2, title: "Salitas Lounge" },
    { id: 3, title: "Barras, Bartenders" },
    { id: 4, title: "Mozos" },
    { id: 5, title: "Grupos Electrógenos" },
    { id: 6, title: "Staff de Dj's" },
    { id: 7, title: "Sonido e Iluminación Profesional" },
    { id: 8, title: "Pista de Baile Led" },
    { id: 9, title: "Pantallas Led y Proyectores" },
    { id: 10, title: "Grabación y Fotografía Profesional" },
    { id: 11, title: "Animadores, Anfitrionas" },
    { id: 12, title: "Hora Loca" },
  ];
  return (
    <SolutionsWrapper>
      <Container>
        <StyledHeading>Nuestras soluciones incluyen:</StyledHeading>
        <Row>
          <Col lg={12}>
            <SolutionBox>
              {items.map((solution) => (
                <SolutionBoxItem key={solution.id}>
                  <BoxIcon
                    icon="fa fa-check"
                    title={solution.title}
                    layout={4}
                  />
                </SolutionBoxItem>
              ))}
            </SolutionBox>
          </Col>
        </Row>
      </Container>
    </SolutionsWrapper>
  );
};

SolutionesArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default SolutionesArea;
