import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container, Row, Col } from "@ui/wrapper";
import Anchor from "@ui/anchor";
import Button from "@ui/button";
import { HeadingType, TextType, ButtonType } from "@utils/types";
import {
  SectionWrap,
  ContactInfoBox,
  StyledHeading,
  StyledText,
  StyledInfoTitle,
} from "./style";

const ContactArea = ({ data }) => {
  const contactQueryData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bg/contact-section-bg-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1192
            height: 630
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
  `);
  const imageData = convertToBgImage(getImage(contactQueryData.file));
  return (
    <SectionWrap {...imageData}>
      <Container>
        <Row alignItems="center">
          <Col lg={6}>
            <StyledHeading>
              Nos apasiona nuestro trabajo y eso siempre ha sido claro.
            </StyledHeading>
            <StyledText>
              Buscamos mejorar adaptándonos a las necesidades y expectativas de
              nuestros clientes.
            </StyledText>
          </Col>
          <Col lg={6}>
            <ContactInfoBox>
              <i className="icon fal fa-phone"></i>
              <StyledInfoTitle>Llámanos</StyledInfoTitle>
              <h2>
                <Anchor
                  path="/contacto"
                  color="secondary"
                  hover={{
                    layout: 2,
                    color: "secondary",
                  }}
                >
                  01 4804935
                </Anchor>
              </h2>

              <Button path="/contacto" mt="20px" minWidth="230px">
                Contáctanos
              </Button>
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

ContactArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
  }),
};

export default ContactArea;
