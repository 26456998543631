import React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@components/page-header/layout-02";
import ServiciosArea from "@containers/servicios/servicios-area";
import SolucionesArea from "@containers/servicios/soluciones-area";
import ContactoArea from "@containers/servicios/contacto-area";

const ServiciosPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Servicios" />
      <Header />
      <main className="site-wrapper-reveal">
        <PageHeader
          pageContext={pageContext}
          location={location}
          title="Servicios"
        />
        <ServiciosArea />
        <SolucionesArea />
        <ContactoArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default ServiciosPage;
